// Fonts
//@import url('https://fonts.googleapis.com/css?family=Nunito');
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';


@font-face {
  font-family: 'LG Smart Italic';
  font-style: normal;
  font-weight: normal;
  src: local('LG Smart Italic'), url('fonts/LG Smart Regular Italic.woff') format('woff');
}


@font-face {
  font-family: 'LG Smart';
  font-style: normal;
  font-weight: normal;
  src: local('LG Smart'), url('fonts/LG Smart Regular.woff') format('woff');
}


@font-face {
  font-family: 'LG Smart Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('LG Smart Bold Italic'), url('fonts/LG Smart Bold Italic.woff') format('woff');
}


@font-face {
  font-family: 'LG Smart Bold';
  font-style: normal;
  font-weight: normal;
  src: local('LG Smart Bold'), url('fonts/LG Smart Bold.woff') format('woff');
}


@font-face {
  font-family: 'LG Smart Light Light';
  font-style: normal;
  font-weight: normal;
  src: local('LG Smart Light Light'), url('fonts/LG Smart Light.woff') format('woff');
}


@font-face {
  font-family: 'LG Smart Light SemiBold';
  font-style: normal;
  font-weight: normal;
  src: local('LG Smart Light SemiBold'), url('fonts/LG Smart SemiBold.woff') format('woff');
}
