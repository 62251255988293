@import "../global";
@import "global";

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

body{
  //background-color: #F5F5F5;
  background-color: #ffffff;
  font-size: 16px;
}

.main-navbar{
  //border-top: #C20C3F solid 4px;
  background-color: #a40033 !important;
  padding: 24px 0;

  .nav-item{
    font-size: 18px;
  }
}

.sub-main-navbar{
  background-color: transparent !important;
  margin-bottom: -94px;
}

.mainpage-content{
  .promoCard{
  }
  .promoImage{
    filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.15));
    border-radius: 12px;
    width: 100%;
    height: 220px;
    object-fit: cover;
  }

  .promoBody{
    a{
      font-weight: 600;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      text-decoration: none;
      color: #000;
      &:hover{
        color: #C4024B;
      }
    }
  }
}

.subpage-header{
  //background-color: #C4024B;
  //padding-top: 90px;
  //padding-bottom: 180px;

  img{
    width: 100% !important;
    height: 450px;
    object-fit: cover;
  }

  h1{
    color: #fff;
    font-size: 36px;
    padding-bottom: 24px;
  }
}

.subpage-content{
  margin-top: -200px;
  margin-bottom: 60px;

  .content{
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 32px;
  }

  .promoBanner{
    filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.15));
    border-radius: 12px;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}

.content-title{
  font-weight: 600;
  //font-size: 33.18px;
  font-size: 28px;
  line-height: 38px;
  padding-bottom: 15px;
  text-align: center;
  //border-bottom: 3px solid rgba(196, 196, 196, 0.2);
  margin-bottom: 30px;
  letter-spacing: 8px;
}

.btn-register{
  color: #ffffff !important;
  background-color: #C4024B !important;
  border-color: #C4024B !important;

  &:hover{
    color: #ffffff !important;
    background-color: #9E003C !important;
    border-color: #9E003C;
  }
}

.other-promo{
  padding: 0;
  margin: 0 0 0 16px;

  a{
    line-height: 12px;
    color: #616161;

    &:hover{
      text-decoration: none;
      color: #C4024B;
    }
  }

  li{
    margin-bottom: 12px;
    line-height: 18px;
  }
}

footer{
  color: rgba(255, 255, 255, 0.9);
  position: relative;
  z-index: 1;

  .footer-top{
    position: relative;
    overflow: hidden;
    z-index: 2;
    background-color: #ffffff;
    padding: 42px 0 12px 0;

    p{
      font-size: 16px !important;
      line-height: unset;
      z-index: 20;
    }
  }


  .footer-bottom{
    //background: rgba(45, 95, 46, 1);
    //background-color: #C4024B;
    background-color: #999999;
    //border-top: 1px solid #819E82;
    color: rgba(255,255,255, 0.8);
    font-size: 16px !important;
    //padding: 24px 0;
    padding: 20px 0 14px 0;

    p{
      margin: 0;
    }
  }

  .sub-footer-bottom{
     background-color: #a40033 !important;
  }

  .busdesc{
    line-height: 24px;
    font-size: 12.8px !important;
    margin-top: 16px;

    a{
      color: rgba(255, 255, 255, 0.5);
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      transition: all .3s;

      &:hover{
        color: #e2ffbd;
        border-bottom: 1px solid rgba(226, 255, 189, 1);
      }
    }
  }

  .header{
    color: #000000;
    font-family: 'LG Smart Bold';
    font-size: 20px;
    font-weight: bolder;
    text-transform: uppercase !important;
    margin: 0 0 24px 0!important;
  }

  .sub-header{
    color: #414141;
    font-size: 16px;
    font-weight: 600;
    margin: -26px 0 24px 0!important;
  }

  .list-of-links{
    margin: 0;
    padding: 0 0 0 20px;
    li{
      line-height: 20px;
    }
    a{
      color: rgba(255, 255, 255, 0.6);
      font-size: 12.8px !important;
      transition: all .3s;
      text-decoration: none;

      &:hover{
        color: rgba(255, 255, 255, 1);
        text-decoration: none;
      }
    }
  }

  .link-list{
    list-style: none;
    margin: 0;
    padding: 0;

    a{
      color: #BEBEBE;
      font-size: 12.8px !important;

      &:hover{
        color: #053E74;
        text-decoration: none;
      }
    }
  }
  .contact-info{
    li {
      font-size: 12.8px;
      //line-height: 18px;
      overflow: auto;
      padding-left: 30px;
      padding-bottom: 3px;

      i{
        font-size: 16px;
        float: left;
        margin-left: -28px;
      }
    }
  }
}


.social-media{
  list-style: none;
  margin: -4px 0 -20px 0;
  //margin: -10px 0 0 0;
  padding: 0;
  clear: both;

  li{
    float: left;
    margin-right: 14.5px;
  }

  a{
    font-size: 20px;
    color: rgba(255, 255, 255, 0.5);
    transition: all .3s;
    &:hover{
      color: rgba(255, 255, 255, 1);
    }
  }

  span{
    display: none;
  }
}


.bs-canvas-overlay {
  opacity: 0;
  z-index: -1;
}

.bs-canvas-overlay.show {
  opacity: 0.85;
  z-index: 1100;
}

.bs-canvas-overlay, .bs-canvas {
  transition: all .4s ease-out;
  -webkit-transition: all .4s ease-out;
  -moz-transition: all .4s ease-out;
  -ms-transition: all .4s ease-out;
}

.bs-canvas {
  top: 0;
  z-index: 1110;
  overflow-x: hidden;
  overflow-y: auto;
  width: 330px;
}

.bs-canvas-left {
  left: 0;
  margin-left: -330px;
}

.bs-canvas-right {
  right: 0;
  margin-right: -330px;
}


#bs-canvas-right{
  background-color: #a40033 !important;
}

.bottom-border-menu{
  display: block;
  border-bottom: 1px solid #e6e6e6;
}

.promoNav{
  border-top: 1px solid #e6e6e6;

  a{
    color: rgba(255, 255, 255, 0.7);
    &:hover{
      text-decoration: none;
      color: rgba(255, 255, 255, 1);
    }
  }
}

.main-header-slider{
  background-color: #a40033 !important;
  color: #ffffff;
  padding: 60px 0;
  margin-bottom: 100px !important;
  text-align: center !important;

  h1{
    font-size: 3.1em;
  }
  p{
    font-size: 1.1em;
  }
}

.bold{
  font-family: "LG Smart Bold", sans-serif;
}

/* Default icon formatting */
i {
  display: inline-block;
  font-style: normal;
  position: relative;
}

/* Additional formatting for arrow icon */
i.arrow {
  /* top: 2pt; Uncomment this to lower the icons as requested in comments*/
  width: 0.5em;
  height: 0.5em;
  border-right: 0.16em solid white;
  border-top: 0.16em solid white;
  transform: rotate(45deg);
}
